
import './styles.scss';

$(function() {

    /*******************************************************************************
    * GC Cookielaw
    * Custom JS code start here
    *******************************************************************************/

    function setCookie(name, value, days) {
        var expires = "";
        var samesite = "; samesite=strict";
        var secure = cookielaw_secure ? "; secure" : "";
        var path = "; path=/";

        if (days) {
            var date = new Date();

            date.setTime(date.getTime() + (days * 24 * 60 * 60));
            expires = "; expires=" + date.toUTCString();
        }

        var cookie_string = name + "=" + (value || "")  + expires + samesite + secure + path;

        document.cookie = cookie_string;
    }

    // function getCookie(name) {
    //     var nameEQ = name + "=";
    //     var ca = document.cookie.split(';');

    //     for(var i=0;i < ca.length;i++) {
    //         var c = ca[i];

    //         while (c.charAt(0)==' ') {
    //             c = c.substring(1,c.length);
    //         }

    //         if (c.indexOf(nameEQ) == 0) {
    //             return c.substring(nameEQ.length,c.length);
    //         }
    //     }

    //     return null;
    // }

    function eraseCookie(name)
    {
        setCookie(name, '', -1);
    }

    function reloadPageIfPossible()
    {
        if (cookielaw_reload == true) {
            location.reload();
        }
    }

    function insertJavascript(scripts)
    {
        if (!scripts) {
            return;
        }

        $('body').prepend(scripts);
    }

    function removeJavascript()
    {
        $('.consent_scripts').not('.essential').remove();
    }

    $('a.cookie-info-toggle').on('click', function(e) {
        e.preventDefault();

        var box = $(this).closest('.consent-element');
        box.find('table').toggleClass('hidden');
    });

    $(".consent-group-checkbox").on('change', function(e) {
        $(this)
            .closest('.consent-item')
            .find('.consent-checkbox')
            .prop('checked', $(this).prop('checked'));
        $('.consent-group-checkbox[value="' + $(this).val() + '"]').prop('checked', $(this).prop('checked'));
        $('.consent-group-checkbox[value="' + $(this).val() + '"]')
            .closest('.consent-item')
            .find('.consent-checkbox')
            .prop('checked', $(this).prop('checked'));
        $('.accept-button-save').text(cookielaw_txt_savesettings);
    });

    $(".consent-checkbox").on('change', function (e) {
        var cg = $(this).data('cg');
        var group = $('.consent-group-checkbox[value="' + cg + '"]').first();
        var modules = $('.consent-group-checkbox[value="' + cg + '"]')
            .closest('.consent-item')
            .find('.consent-checkbox');
        var checkedNumber = 0;
        modules.each(function() { $(this).prop('checked') == true && checkedNumber ++; });
        $('.consent-group-checkbox[value="' + cg + '"]').prop('checked', modules.length == checkedNumber);
    });

    $(".consent-checkbox").trigger('change');

    $(".accept-button-save-all").on('click', function (e) {
        e.preventDefault();

        setCookie('gc_cookielaw', 'all', 1000);

        $("#GcCookieBox").hide();
        $("#GcCookieBoxAlter").show();

        $.ajax({
            url: $(this).attr('href'),
            type: 'POST',
            cache: false,
            async: true,
            data: {
                ajax: 1,
            },
            dataType: 'json',
            success: function(data) {
                insertJavascript(data.scripts);
                reloadPageIfPossible();
            }
        });
    });

    $(".change_settings").on('click', function (e) {
        e.preventDefault();

        eraseCookie('gc_cookielaw');

        $("#GcCookieBoxAlter").hide();
        $("#GcCookieBox").show();

        $.ajax({
            url: $(this).attr('href'),
            type: 'POST',
            cache: false,
            async: true,
            data: {
                ajax: 1,
            },
            dataType: 'json',
            success: function () {
                removeJavascript();
            }
        });
    });

    $(".accept-button-save").on('click', function (e) {
        e.preventDefault();

        var consent_ids = new Array();
        var cookie_string = '';

        $(".consent-checkbox:checked").each(function () {
            if ($(this).prop('checked')) {
                consent_ids.push($(this).val());
            }
        });

        cookie_string = consent_ids.join('-');

        if (cookie_string == '') {
            cookie_string = 'essential';
        }

        setCookie('gc_cookielaw', consent_ids.join('-'), 1000);

        $("#GcCookieBox").hide();
        $("#GcCookieBoxAlter").show();

        $.ajax({
            url: $(this).attr('href'),
            type: 'POST',
            cache: false,
            async: true,
            data: {
                ajax: 1,
                consent_ids: consent_ids
            },
            dataType: 'json',
            success: function(data) {
                insertJavascript(data.scripts);
                reloadPageIfPossible();
            }
        });
    });

    $('a[data-cookie-settings]').on('click', function(e) {
        $(".cookie-box-simple").hide();
        $("#GcCookieBox .wrapper").addClass('advanced');
        $(".cookie-box-advanced").show();
    });

    $(".back-link a").on('click', function(e) {
        $(".cookie-box-simple").show();
        $(".cookie-box-advanced").hide();
        $("#GcCookieBox .wrapper").removeClass('advanced');
    });

    if($('#cookiebanner').length >= 1) {
        var footerPad = parseInt($('#footer').css('paddingBottom'));
        var form = $('#cookiebanner');

        if(!($('#cookiebanner').hasClass('guideline'))) {
            var cookiebannerHeight = parseInt($('#cookiebanner .container').outerHeight());
            $('#footer').css('paddingBottom', (footerPad + cookiebannerHeight) + 'px');
        }

        $('#cookiebanner .background').on('click', function(e) {
            e.preventDefault();

            form.removeClass('guideline');
            $('button[name=submitCookielawDeny]').parent().remove();
        });

        $('#cookiebanner button').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            var action = $(this).attr('name');

            if (action == 'submitCookielawDeny') {
                form.removeClass('guideline');
                $('button[name=submitCookielawDeny]').parent().remove();
                form.remove();
                $('#footer').css('paddingBottom', '10px');
            } else if (action == 'submitCookielaw') {
                form.hide();

                $.getJSON(
                    form.attr('action'),
                    {
                        'ajax': 1,
                        'action': 'submitCookielaw',
                        'data':form.serialize(),
                        'dataType': 'json'
                    },
                    function(result) {
                        if(result == 'ok') {
                            form.remove();
                            if($(this).hasClass('guideline')) {
                                $('#footer').css('paddingBottom', footerPad + 'px');
                            }
                        } else {
                            form.show();
                        }
                    }
                );
            }
        });
        $('#cookiebanner .consent-preferences a').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('#cookiebanner .consent').toggle();
        });
    }

    $("#GcCookieBox .wrapper").on('click', function (e) {
        e.stopPropagation();
    });

    $("#GcCookieBox .close-me, #GcCookieBox .close").on('click', function (e) {
        $("#GcCookieBox .wrapper").addClass('animated rotateOutDownRight');
        $("#cookie-box-outer").fadeOut();
        setTimeout(function() {
           $("#GcCookieBox").hide();
       }, 800);
    });

    if (typeof cookielaw_force_delete_cookie != 'undefined' && cookielaw_force_delete_cookie) {
        eraseCookie('gc_cookielaw');
    }
});
